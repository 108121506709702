<template>
	<div class="redirec__view">
		<v-progress-circular
			:size="170"
			:width="15"
			color="#f1830d"
			indeterminate
		></v-progress-circular>
		<div class="mt-10 label-connexion">Connexion</div>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	export default {
		name: 'Redirec',
		data: () => ({
			code: ''
		}),
		methods: {
			...mapActions('auth', [
				"getTokenAction",
			])
		},
		created: function() {
			var _this = this;
			this.getTokenAction(this.$route.query.code)
			.then(() => {		
				_this.$router.push('/');
			})
			.catch(() => {
				_this.$router.push('/login');
			})
		}
	};
</script>

<style lang="scss">
.redirec__view
{
	text-align: center;
	padding-top: 200px;

	.label-connexion
	{
		font-size: 45px;
		font-weight: bold;
	}
}
</style>